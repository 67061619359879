
<template>
    <div>
    <b-card>
      <b-row class="">
        <b-col class="col-md-12">
          <h2 class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mt-1">
            {{ $t("failed_emails") }}
          </h2>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
        <Table
        :items="emails"
        striped
        :url="url"
        responsive
        hover
        class="position-relative mt-2"
        show-empty
        :fields="fields"
      >
        <template #head()="scope">
          <div class="text-nowrap">{{ $t(scope.label) }}</div>
        </template>
        <template #cell(created_at)="data">
          <p style="width:140px !important;">{{ data.value.substring(10,0) }}  {{ data.value.substring(11,16) }}</p>
        </template>
        </Table>
    </b-card>
</div>
  </template>
  
  <script>
  import {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
  } from "bootstrap-vue";
  import CustomLoader from "@/components/Common/CustomLoader";
  import Table from "@/components/Common/Table2";
  import Ripple from "vue-ripple-directive";
  
  export default {
    components: {
      Table,
      BTable,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BPagination,
      BTr,
      BTh,
      BButton,
      BLink,
      CustomLoader,
    },
    directives: {
      Ripple,
    },
    computed: {
      rows() {
        return this.totalItems;
      },
    },
    data() {
      return {
        page: 1,
        totalItems: 0,
        url: '/failedEmails',
        emails: null,
        fields: [
        {
          key: "email",
          label: "email_type",
        },
        {
          key: "to_email",
          label: "email_to",
        },
        {
          key: "created_at",
          label: "created_at",
        },
        
      ],
      };
    },
    created() {
        this.getEmails();
    },
    methods: {
    getEmails(){
    this.$http
        .post(`/failedEmails?page=` + this.page)
        .then((res) => {
          if (res) {
            this.emails = res.data.value.data;
            this.totalItems = res.data.value.total;
            this.page = res.data.value.current_page;
          }
        });
  },
    },
  };
  </script>
  
  <style scoped>
  </style>
  